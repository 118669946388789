import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import HeaderTemp from "./components/HeaderTemp";
import FooterTemp from "./components/FooterTemp";
import Home from "./pages/home/Index";
import About from "./pages/about/Index";
import Blog from "./pages/blog/Index";
import MetaTags from 'react-meta-tags';
import Informations from "./pages/info/Index";
import BlogContent from './pages/blog/Content';
import InfoContent from "./pages/info/Content"
import Contact from "./pages/contactUs/contact";
import ContactJob from "./pages/contactUs/index";
import Projects from "./pages/projects/Index";
import Articles from "./pages/articles/Index";


import {
    BrowserRouter ,
    Switch,
    Route,
    useLocation ,
} from "react-router-dom";


const  ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }


function App() {
    return (
        <BrowserRouter >
        <ScrollToTop/>
            <Container-fluid>
                {/* <MetaTags>
                    <title>hallmarcom</title>
                    <meta name="description" content="sdasdas" />
                </MetaTags> */}
                <div className="App">
                    <HeaderTemp/>
                    <Switch >
                        <Route exact path="/info/content/:id" >
                            <InfoContent/>
                        </Route>
                        <Route path="/blog/content/:id">
                            <BlogContent/>
                        </Route>
                        <Route exact  path="/articles/:id">
                            <Articles/>
                        </Route>
                        <Route exact path="/projects">

                            <Projects/>
                        </Route>
                        <Route path="/about">
                            <About/>
                        </Route>
                        <Route path="/blog">
                            <Blog/>
                        </Route>
                        <Route path="/info">
                            <Informations/>
                        </Route>
                        <Route path={'/contactus/job'}>
                            <ContactJob/>
                        </Route>
                        <Route path={'/contactus'}>
                            <Contact/>
                        </Route>
                        <Route exact path="/home">
                            <Home/>
                        </Route>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                    </Switch>
                    <FooterTemp/>
                </div>
            </Container-fluid>

        </BrowserRouter >

    );
}

export default App;
