import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "../../components/CardInfo";
//import {Switch, Route} from 'react-router-dom';
import Config from "../../config";
//import Content from '../content';
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@material-ui/core";
import MetaTags from 'react-meta-tags';

const Index = () => {
  const [data, setData] = useState();
  const [categories, setCategories] = useState();
  const [active, setActive] = useState(0);

  const CategoriesList = (props) => {
    const getCategories = (props) => {
      try {
        setActive(props);
        fetch(Config.baseUrl + "article-api.php?act=category&id=" + props)
          .then((response) => response.json())
          .then((response) => {
            setData(response);
          });
      } catch (e) {
        console.error(e);
      }
    };

    const boxList = {
      textAlign: "center",
      borderBottom: "3px solid #D7D7D7",
      boxSizing: "content-box",
      padding: "5px",
      maxWidth: "130px",
      cursor: "pointer",
    };
    const Trian = styled.span`
      display: inline-block;
      background: transparent;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9px 11px 0 12px;
      border-color: #d7d7d7 transparent transparent transparent;
      position: absolute;
      bottom: -9px;
      left: 0;
      right: 0;
      margin: 0 auto;
    `;

    return (
      <Row className={"m-0 justify-content-center"}>
        <Col md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              דף הבית
            </Link>
            <Typography color="textPrimary">{"מידע מקצועי"}</Typography>
          </Breadcrumbs>
        </Col>
        <Col md={12}>
          <h1 style={{ fontSize: 28 }}>{"מידע מקצועי"}</h1>
        </Col>
        <Col md={10}>
          <Row className={"justify-content-between test"}>
            <Col
              className={0 === active ? "activeCat" : ""}
              style={boxList}
              onClick={() => {
                getCategories(0);
              }}
            >
              הכל
            </Col>
            {props.categories
              ? props.categories.map((elem, key) => {
                  return (
                    <Col
                      key={key}
                      className={elem.id === active ? "activeCat" : ""}
                      style={boxList}
                      onClick={() => {
                        getCategories(elem.id);
                      }}
                    >
                      {elem.title}
                    </Col>
                  );
                })
              : ""}
          </Row>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    async function getAPI() {
      try {
        let response = await fetch(Config.baseUrl + "article-api.php");
        response = await response.json();
        setData(response["content"]);
        setCategories(response["categories"]);
      } catch (e) {
        console.error(e);
      }
    }

    getAPI();
  }, []);

  return (
    <div>
      {!data ? (
        <Col md={12} className={"text-center loader"}>
          loading...
        </Col>
      ) : (
        <Col md={12}>
          <Row>
          <MetaTags>
            <title>{'הולמרקום - מידע מקצועי'}</title>
            <meta name="description" content={'הולמרקום - חברת הדיילות הגדולה והמנוסה בישראל. לקבלת מידע מקצועי בנושא דיילות לאירועים ולקידום מכירות היכנסו עכשיו לאתר'} />
          </MetaTags>
            <Col md={12}>
              <CategoriesList categories={categories} />
            </Col>
            <Col md={12}>
              <Row>
                {data
                  ? data.map((elem, key) => {
                      return (
                        <Col md={4}>
                          <Card
                            img={elem.img}
                            title={elem.title}
                            promo={elem.promo}
                            id={elem.id}
                            moreInfo={true}
                            to={"/articles/" + elem.id}
                          />
                        </Col>
                      );
                    })
                  : ""}
              </Row>
            </Col>

            {/*<Col className={'text-center mt-5 justify-content-center d-flex'} md={12}>*/}
            {/*    <Pagination className={'paginationStyle'}>*/}
            {/*        <Pagination.Prev/>*/}
            {/*        <Pagination.Item>{1}</Pagination.Item>*/}
            {/*        <Pagination.Item active>{2}</Pagination.Item>*/}
            {/*        <Pagination.Item>{3}</Pagination.Item>*/}
            {/*        <Pagination.Next/>*/}
            {/*    </Pagination>*/}
            {/*</Col>*/}
          </Row>
        </Col>
      )}
    </div>
  );
};

export default Index;
