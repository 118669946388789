/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Config from "../../config";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

const style = {
  textAlign: "right",
  marginTop: "50px",
};

const CheckList = (props) => {
  const titleCheckList = {
    fontWeight: 900,
  };

  return (
    <Col>
      <Row className="text-center">
        <Col className={"col-12 mb-4"}>
          <img
            src={require("../../images/about/" + props.img + ".svg")}
            alt=""
          />
        </Col>
        <Col className={"col-12"} style={titleCheckList}>
          <h3>{props.title}</h3>
        </Col>
        <Col>
          <p>{props.promo}</p>
        </Col>
      </Row>
    </Col>
  );
};

const WhyWe = (props) => {
  const whyBox = {
    borderRight: "5px solid " + (props.colors ? props.colors : "#F36F1D"),
    backgroundColorolor: "#FFFFFF",
    boxShadow: "0 0 5px 3px rgba(37,37,37,0.08)",
    marginTop: "15px",
    minHeight: "72px",
    alignItems: "center",
    display: "flex",
  };
  const whyBoxMob = {
    borderRight: "5px solid " + (props.colors ? props.colors : "#F36F1D"),
    backgroundColorolor: "#FFFFFF",
    boxShadow: "0 0 5px 3px rgba(37,37,37,0.08)",
    marginTop: "15px",
    minHeight: "72px",
    alignItems: "center",
    display: "flex",
  };

  return (
    <Row>
      <Col md={12} style={window.innerWidth > 768 ? whyBox : whyBoxMob}>
        {props.title}
      </Col>
    </Row>
  );
};

const SectionTwoLeft = (props) => {
  const styleBox = {
    backgroundColor: "#008889",
    color: "white",
  };
  const textStyle = {
    padding: "35px",
    fontSize: "20px",
  };
  return (
    <Row style={styleBox}>
      <Col md={12} style={textStyle}>
        <p>
          <h2>{props.title}</h2>
          <span style={{ color: "while" }}>
            <p dangerouslySetInnerHTML={{ __html: props.text }} />
          </span>
        </p>
      </Col>
      <Col md={12} className={"p-0"}>
        <img
          src={require("../../images/about/aaaa.png")}
          className={"img-fluid w-100"}
          alt=""
        />
      </Col>
    </Row>
  );
};

const About = () => {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchAPI() {
      try {
        let response = await fetch(Config.baseUrl + "aboutus-api.php?act=get");
        response = await response.json();
        setData(response);
      } catch (e) {
        console.error(e);
      }
    }

    fetchAPI();
  }, []);

  return (
    <>
      <Col md={12}>
        <MetaTags>
          <title>הולמרקום - קצת עלינו</title>
          <meta name="description" content={'הולמרקום - חברת הדיילות הגדולה והמנוסה בישראל. לקבלת מידע ופרטים נוספים אודות החברה ושרותייה היכנסו עכשיו לאתר'} />
        </MetaTags>
        {
          <Row>
            <Col>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  דף הבית
                </Link>
                <Typography color="textPrimary">{"קצת עלינו"}</Typography>
              </Breadcrumbs>
            </Col>
          </Row>
        }
      </Col>
      <Col className={"text-center mb-5"}>
        <Row>
          <Col md={12} className="text-right">
            <h1 style={{ fontSize: 28 }}>{"קצת עלינו"}</h1>
          </Col>
          {!data ? (
            <Col md={12} className={"loader"}>
              Loading...
            </Col>
          ) : (
            <Col>
              <Row style={style}>
                <Col>
                  <Row>
                    {data["sectionOne"].map((elem, key) => {
                      return (
                        <CheckList
                          key={key}
                          title={elem.title}
                          promo={elem.text}
                          img={elem.color}
                        />
                      );
                    })}
                  </Row>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col md={10}>
                          <p>
                            <h2>{data["aboutHallmarcom"]["title"]}</h2>
                          </p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data["aboutHallmarcom"]["text"],
                            }}
                          />
                        </Col>
                        <Col md={12}>
                          <h2>למה אנחנו</h2>
                        </Col>
                        <Col md={6}>
                          {data["whyus"].map((elem, key) => {
                            if (elem.title) {
                              return (
                                <WhyWe
                                  key={key}
                                  title={elem.title}
                                  colors={elem.color}
                                />
                              );
                            }
                          })}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <SectionTwoLeft
                        title={data["vision"]["title"]}
                        text={data["vision"]["text"]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </>
  );
};

export default About;
