import React from "react";
import {Col, Row} from "react-bootstrap";
import Config from "../config";


const Index = (props) => {

    const titleStyle = {
        fontSize: '25px',
        fontWeight: 800
    }

    return (
        <Col md={12} className={'mt-5'}>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <h1 style={titleStyle}>{props.title}</h1>
                        </Col>
                        <Col md={12} className={'mt-3'} dangerouslySetInnerHTML={{__html: props.text}} />
                    </Row>
                </Col>
                <Col md={6}>
                    <Row className={'text-left'}>
                        <Col md={12}>
                            <img src={Config.imgUrl+ props.img} alt="" style={{width: '100%'}}/>
                        </Col>
                        <Col md={12} className="mt-5">
                            <img src={Config.imgUrl+ props.twoImg} alt="" style={{width: '100%'}}/>
                        </Col>
                        {/*<Col md={12} className={'mt-4'}>*/}
                        {/*    <img src={require('../images/about/aaaa.png')}/>*/}
                        {/*</Col>*/}
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}

export default Index;
