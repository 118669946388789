import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Contact from "../../components/ContactUs";
import Config from "../../config";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

function ContactUs() {
  const [username, setUsername] = useState();
  const [companes, setCompanes] = useState();
  const [phone, setPhone] = useState();
  const [mail, setMail] = useState();
  const [topicText, setTopicText] = useState(false);
  const [text, setText] = useState();

  const [sendData, setSendData] = useState(false);
  const [error, setError] = useState();

  const submit = (e) => {
    e.preventDefault();

    if (!username || !phone || !mail) {
      setError("השדות המסומנים ב * הם שדות חובה");
      return false;
    }
    const formData = new FormData();
    formData.append('username', username);
    formData.append('lastname', companes);
    formData.append('mail', mail);
    formData.append('phone', phone);
    formData.append('title', topicText);
    formData.append('text', text);
    formData.append('job', 0);
    // const data = {
    //   username: username,
    //   lastname: companes,
    //   mail: mail,
    //   phone: phone,
    //   title: topicText,
    //   text: text,
    //   job: 0,
    // };

    // file: fileCv
    fetch(Config.baseUrl + "contact-api.php?act=saveDrushimApi", {
      method: "post",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.act) {
          setSendData(true);
        }
      });
  };

  const usernameHandel = (e) => {
    setUsername(e.target.value);
  };
  const company = (e) => {
    setCompanes(e.target.value);
  };
  const phoneHandel = (e) => {
    setPhone(e.target.value);
  };
  const mailHandle = (e) => {
    setMail(e.target.value);
  };
  const topic = (e) => {
    setTopicText(e.target.value);
  };
  const textarea = (e) => {
    setText(e.target.value);
  };

  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await fetch(
          Config.baseUrl + "contact-api.php?act=getContent"
        );
        response = await response.json();
        console.log("????");
        setData(response);
      } catch (e) {
        console.error(e);
      }
    }

    fetchMyAPI();
  }, []);

  return data ? (
    <div>
      <MetaTags>
        <title>{data && data.data && data.data.titleHEB ? 'הולמרקום - '+data.data.titleHEB : ""}</title>
        <meta name="description" content={'הולמרקום - חברת הדיילות הגדולה והמנוסה בישראל לאירועים המספקת פתרונות שיווק מתקדמים ויחודיים, הפקת כנסים וקידום מכירות, צרו עימנו קשר לקבלת הפרטים נוספים'} />
      </MetaTags>
      <Col md={12}>
        {
          <Row>
            <Col>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  דף הבית
                </Link>
                <Typography color="textPrimary">{"צור קשר"}</Typography>
              </Breadcrumbs>
            </Col>
          </Row>
        }
      </Col>

      <Col md={12}>
        <Row className={"m-0"}>
          <Col md={12}>
            <Contact
              contactus={true}
              title={''}
              promo={data.data.textHEB}
              submit={submit}
              send={sendData}
              error={error}
              address={data.address}
             phone={data.phone}
            fax={data.fax}
            email={data.email}
              inputs={[
                {
                  col: 6,
                  name: "username",
                  value: "",
                  placeholder: "*שם מלא",
                  require: true,
                  onchange: usernameHandel,
                },
                {
                  col: 6,
                  name: "",
                  value: "",
                  placeholder: "*חברה",
                  require: true,
                  onchange: company,
                },
                {
                  col: 6,
                  name: "username",
                  value: "",
                  placeholder: "*טלפון",
                  require: true,
                  onchange: phoneHandel,
                },
                {
                  col: 6,
                  name: "username",
                  value: "",
                  placeholder: "*כתובת אימייל",
                  require: true,
                  onchange: mailHandle,
                },
                {
                  col: 12,
                  name: "username",
                  value: "",
                  placeholder: "*נושא הפנייה",
                  require: true,
                  onchange: topic,
                  options: data.list,
                },
                {
                  col: 12,
                  textarea: true,
                  name: "username",
                  value: "",
                  placeholder: "הודעה",
                  require: true,
                  onchange: textarea,
                },
              ]}
              filesupload={false}
            />
          </Col>
        </Row>
      </Col>
    </div>
  ) : (
    <Col className={"loader"}>Loading...</Col>
  );
}

export default ContactUs;
