import React, {useEffect, useState} from "react";
import Config from "../../config";
import {useParams} from "react-router-dom";
import Content from '../content'
import {Breadcrumbs, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Col, Row} from "react-bootstrap";


const Index = () => {
    const [data, setData] = useState();
    let {id} = useParams();

    useEffect(() => {
        async function getAPI() {
            try {
                // // todo: get one page content
                let response = await fetch(Config.baseUrl + 'blog-api.php?act=get&id=' + id);
                response = await response.json();
                setData(response);

            } catch (e) {
                console.error(e);
            }
        }
        getAPI();
    })

    return (
        (data ?
            <>
            <Col md={12}>
                   {

                       <Row>
                           <Col>
                               <Breadcrumbs aria-label="breadcrumb">
                                   <Link color="inherit" to="/" >
                                       דף הבית
                                   </Link>
                                   <Link color="inherit" to="/blog" >
                                       כתבו עלינו
                                   </Link>
                                   <Typography color="textPrimary">{data.title}</Typography>
                               </Breadcrumbs>
                           </Col>

                       </Row>

                   }
            </Col>
            <div>
                <Content title={data.title} promo={data.promo}/>
            </div>
                </>
            : '')
    )
}

export default Index;
