import { Col, Row } from "react-bootstrap";
import React from "react";
//import {Link} from "react-router-dom";
import Config from "../config";
import MetaTags from 'react-meta-tags';


const Card = (props) => {
  const titleStyle = {
    fontSize: "28px",
    marginTop: "15px",
    fontWeight: 800,
    lineHeight: "31px",
  };

  const boxStyle = {
    borderBottom: "4px solid black",
    margin: 0,
    paddingTop: "25px",
    height: "100%",
  };

  const promoStyle = {
    textAlign: "right",
  };

  const redMore = {
    alignSelf: "flex-end",
    margin: "0 auto",
    padding: "15px",
    background: "#008889",
    marginBottom: "15px",
    textAlign: "center",
    color: "white",
    fontSize: "20px",
    fontWeight: 800,
  };
  const imgStyle = {};

  return (
    <Row style={boxStyle}>
      <a
        href={props.id ? 'articles/'+props.id : props.file}
        target="_blanck"
        style={{ display: "grid" }}
      >
        <Col md={12}>
          <Row>
            <Col md={12} className={"text-center"} style={imgStyle}>
              <img
                src={Config.imgUrl + props.img}
                alt=""
                className="img-fluid"
              />
            </Col>
            <Col md={12}>
              <h2 style={titleStyle}>{props.title}</h2>
            </Col>
            <Col md={12}>
              <p
                style={promoStyle}
                dangerouslySetInnerHTML={{ __html: props.promo }}
              />
            </Col>
          </Row>
        </Col>
        {props.moreInfo ? (
          <Col md={4} style={redMore}>
            <a href={props.to} style={{ color: "white" }}>
              {" "}
              קרא עוד...
            </a>
          </Col>
        ) : (
          ""
        )}
      </a>
    </Row>
  );
};

export default Card;
