import React from "react";
import {Col, Row} from "react-bootstrap";
import InputTemp from "./InputComponent";

function ContactUs(props) {

    //styles
    const uploadFile = ({
        border: 'none',
        background: '#89C740',
        padding: '5px',
        color: 'white',
        fontSize: '15px',
        width: '100%',
        textAlign: 'center'
    });
    const sendBtn = ({
        border: 'none',
        padding: '12px',
        background: 'black',
        color: 'white',
        fontSize: '25px',
        fontWeight: 800
    });
    const TitleDetails = ({
        fontSize: '35px',
        fontWeight: 800,
        width: '70%',
        color: 'white'
    })
    const detailsSection = {
        background: '#008889'
    };
    const formLinks = {
        background: '#89C740',
        width: '100%',
        display: 'flex',
        padding: '15px',
        color: 'white',
        textAlign: 'center',
        justifyContent: 'center',
        fontSize: '20px'
    };
    const detailsPading = {
        padding: '25px'
    };
    const detailsEmail = {
        display: 'grid',
        fontSize: '20px',
        color: 'white',
        paddingTop: '20px'
    };
    const thisPage = {
        paddingTop: '50px',
        paddingBottom: '80px'
    }
    //styles


    return (
        <Row className={'m-0'} style={thisPage}>
            <Col md={12}>
                <Row>
                    <Col md={6}>
                        <form onSubmit={props.submit}>
                            <Col md={12}>
                                <h3><b>{props.title}</b></h3>
                            </Col>
                            <Col md={9}>
                            <p dangerouslySetInnerHTML={{__html: props.promo}}/>
                                <p>
                                    <b>
                                        {props.titleForm}
                                    </b>
                                </p>
                            </Col>
                            {
                                (props.send ?
                                        <Col className={'text-center alert-success'}>הפרטים נשלחו בהצלחה</Col>
                                        :
                                        <Col md={12}>
                                            <Row>
                                                <Col md={12}>
                                                    <Row>
                                                        {
                                                            props.inputs.map((value, key) => {

                                                                if(value.textarea){
                                                                    return (
                                                                        <Col md={value.col} className={'mt-2'} key={key}>
                                                                        <textarea className={'w-100'}
                                                                                  rows={4}
                                                                                  placeholder={'הודעה'}
                                                                                  onChange={(e) => value.onchange(e)}/>
                                                                    </Col>
                                                                    )
                                                                }

                                                                if(value.options){
                                                                    return (
                                                                    <Col md={value.col} className={'mt-2'} key={key}>
                                                                        <select  onChange={(e) => value.onchange(e)} style={{padding: '15px',width: '100%',}} >
                                                                        <option>נושא פנייה</option>
                                                                            {value.options.map((value, key) => {
                                                                                return <option value={value.titleHEB}>{value.titleHEB}</option>
                                                                            })}
                                                                        </select>
                                                                    </Col>
                                                                    )
                                                                }


                                                                return (
                                                                     <Col md={value.col} className={'mt-2'} key={key}>
                                                                            <InputTemp placeholder={value.placeholder}
                                                                                       onChange={(e) => value.onchange(e)}/>
                                                                        </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                </Col>

                                                <Col md={12} className={'mt-2'}>
                                                    <Row className={'justify-content-end'}>
                                                        <Col md={9} className={'text-danger'}>
                                                            {props.error}
                                                        </Col>
                                                        {
                                                            props.filesupload ?
                                                                <Col md={3}>
                                                                    <label htmlFor={'upFile'} style={uploadFile}>
                                                                        העלאת קו"ח
                                                                    </label>
                                                                    <input style={uploadFile}
                                                                           className={'w-100'}
                                                                           type='file'
                                                                           onChange={(e) => props.filesupload.onchange(e)  }
                                                                           id={'upFile'}
                                                                           hidden={true}
                                                                    />

                                                                </Col>
                                                                :
                                                                ''
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={6}>
                                                    <input style={sendBtn} className={'w-100'} type='submit'
                                                           value={'שליחה'}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                )
                            }
                        </form>
                    </Col>
                    <Col md={6}>
                        <Row className={'justify-content-end m-0'}>

                            <Col md={10} style={detailsSection}>
                                <Row>
                                    {
                                        (props.contactus) ?
                                           <Col md={12}>
                                               <Row>
                                                   <Col md={12} className={'mt-3'} style={TitleDetails}>
                                                       <b> <h2>פרטי התקשרות</h2></b>
                                                   </Col>
                                                   <Col className={'mt-3 mb-3 text-right col-12'} style={detailsEmail}>
                                                       <p> <span style={{display:"inline-block" ,maxWidth:"58px" ,width:"100%"}}>רחוב : </span> {props.address}</p>
                                                       <p> <span style={{display:"inline-block" ,maxWidth:"58px" ,width:"100%"}}>  טלפון :</span> <a style={{color:"#fff"}} href={props.phone}> {props.phone}</a></p>
                                                       <p><span  style={{display:"inline-block" ,maxWidth:"58px" ,width:"100%"}}> פקס :</span>  {props.fax}</p>
                                                       <p> <span style={{display:"inline-block" ,maxWidth:"58px" ,width:"100%"}}>   דוא"ל : </span> <a  style={{color:"#fff"}} href={"mailto:"+props.email}> {props.email}</a></p>

                                                   </Col>
                                               </Row>
                                           </Col>
                                        : ''
                                    }
                                    <Col md={12} className={'p-0'}>
                                        <img src={require('../images/bbbb.png')} className={'img-fluid w-100'} alt=""/>
                                    </Col>

                                    {
                                        (props.contactus) ?
                                           ''
                                            :
                                            <Col style={detailsPading}>
                                                <Row>
                                                    <Col md={12} style={TitleDetails}>
                                                    {props.titleBanner.title}
                                                    </Col>
                                                    <Col md={5}>
                                                        <a style={formLinks}
                                                           href={props.link101.url}
                                                           target={'_blanck'} className={'w-100'}> {props.link101.title} </a>
                                                    </Col>
                                                    <Col md={7}>
                                                        <a style={formLinks}
                                                           href={props.Linkemployee.url}
                                                           target={'_blacnk'} className={'w-100'}>
                                                               {props.Linkemployee.title}
                                                        </a>
                                                    </Col>
                                                    <Col md={12} style={detailsEmail} dangerouslySetInnerHTML={{__html: props.titleBanner.text}}>
                                                       
                                                    </Col>
                                                </Row>
                                            </Col>
                                    }

                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ContactUs;
