import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
//import Pagination from "react-bootstrap/Pagination";
import Card from "../../components/CardInfo";
import { Switch, Route } from "react-router-dom";
import Config from "../../config";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

const Index = () => {
  const [data, setData] = useState();

  useEffect(() => {
    async function getAPI() {
      try {
        let response = await fetch(Config.baseUrl + "blog-api.php");
        response = await response.json();
        setData(response);
      } catch (e) {
        console.error(e);
      }
    }

    getAPI();
  }, []);

  return (
    <div>
      {!data ? (
        <Col md={12} className={"text-center loader"}>
          loading...
        </Col>
      ) : (
        <Col md={12}>
          <Switch>
            <Route path={"/blog"}>
              <Row>
                <MetaTags>
                  <title>הולמרקום - כתבו עלינו</title>
                  <meta name="description" content={'הולמרקום - חברת הדיילות הגדולה והמנוסה בישראל. לצפייה במכתבים שכתבו על החברה ולקבלת מידע אודות השירותים השאנו מציעים היכנסו עכשיו לאתר'} />
                </MetaTags>
                <Col md={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/">
                      דף הבית
                    </Link>
                    <Typography color="textPrimary">כתבו עלינו</Typography>
                  </Breadcrumbs>
                </Col>
                <Col md={12}>
                  <h1 style={{ fontSize: 28 }}>כתבו עלינו</h1>
                </Col>
                {data.content.map((elem, key) => {
                  return (
                    <Col md={4} key={key}>
                      <Card
                        img={elem.img}
                        title={elem.title}
                        id={elem.id}
                        to={elem.link}
                        file={elem.file}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Route>
          </Switch>
        </Col>
      )}
    </div>
  );
};

export default Index;
