/* eslint-disable react/jsx-no-duplicate-props */
import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Link} from 'react-router-dom';
import logo from "./images/Logo.png";
import FacebookIcon from "./images/Facebook.png";
import YouYube from "./images/YouYube.png";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Config from "../config";



const TopLinks = (props) => {

    const rowBox = {
        padding: '16px',
        paddingTop: '18px',
        paddingRight: '0px'
    };
    const linkStyle = {
        fontSize: '17px',
        fontWeight: '400',
        color: '#5a5a5a',
    };

    function TabLink(props) {
        return <Link className="col-auto" style={linkStyle} to={props.target}>{props.title}</Link>;
    }

    return (
        <Row style={rowBox}>
            {
                props.links ?
                    props.links.map((elem, key) => {
                        return (<TabLink key={key} title={elem.titleHEB} target={elem.url}/>)
                    })
                    : ''
            }
        </Row>
    )
};

const Icons = (props) => {
    return (
        <Col className={"col-auto pr-0"}>
            <img src={props.img} alt=""/>
        </Col>
    )
};
const LinkSocial = (prop) => {

    if(prop.link) {
        return (
            <a href={prop.link}   target={"_blank"} >
                <Icons img={prop.icon}/>
            </a>
        )
    }else{
        return  "";
    }
};

const NavBarLink = (props) => {
    const [anchorEl, setAnchorEl] = React.useState();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [menuHover , setMenuHover] = useState(false);


    if (props.morelinks) {
        return (
            <Col className={"col-md-auto col-6 text-right"} onMouseEnter={() => {setMenuHover(true)}} onMouseLeave={() => {setMenuHover(false)}} >
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
                        style={{fontSize: '16px', fontWeight: '500' , paddingBottom: '3px', paddingTop:'3px'}} className={(menuHover ? 'HoverStyle' : '')}>
                    {props.title}
                </Button>
                <Menu
                    style={{justifyContent: 'right', display: 'grid'}}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    transformOrigin={{vertical: "top", horizontal: "center"}}
                >
                    {props.morelinks.map((elem, key) => {
                        return  <MenuItem key={key} onClick={handleClose}><Link to={elem.url}>{elem.title}</Link></MenuItem>
                    })}
                </Menu>
            </Col>
        )
    } else {
        return (
            <Col className={"col-md-auto col-6 text-right "} onMouseEnter={() => {setMenuHover(true)}} onMouseLeave={() => {setMenuHover(false)}} >
                <Button  aria-controls="simple-menu" className={(menuHover ? 'HoverStyle' : '')} aria-haspopup="true" onClick={handleClick}
                        style={{fontSize: '16px', fontWeight: '500', paddingBottom: '3px', paddingTop:'3px'}}>
                   <Link to={props.url}> {props.title}</Link>
                </Button>
            </Col>
            // <Col className={"col-auto"}><Link href={props.url}> {props.title} </Link></Col>
        )
    }
};

const HeaderTemp = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        async function fetchAPI() {
            try {
                let response = await fetch(Config.baseUrl+'header-api.php?act=get');
                response = await response.json();
                setData(response);
            } catch (e) {
                console.error(e);
            }

        };
        fetchAPI();
    }, [])

    return (
        <Col md={12}>
            <Row>
                <Col md={12} className={'text-center'}>
                    <TopLinks links={data.toplinks}/>
                </Col>
                <Col md={12}>
                    <Row>
                        <Col md={3}/>
                        <Col className={'text-center'} md={6}>
                            <Link to={'/'}>
                            <Icons img={logo}/>
                            </Link>
                        </Col>
                        <Col md={3} className={"align-self-end"}>
                            <Row className={'justify-content-end'} className={window.innerWidth > 768 ? 'justify-content-end' : 'justify-content-center mt-4'}>
                                <LinkSocial link={data.facebook} icon={FacebookIcon}/>
                                <LinkSocial link={data.youtube} icon={YouYube}/>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col md={12} className={""}>
                    <Row className={"justify-content-center navbarHeader m-0 mt-5 mb-3"}>

                        <Col md={9}>
                            <Row className={"justify-content-between "}>
                                {
                                    data['articleLinks'] && data['articleLinks'].map((elem, key) => {
                                        return <NavBarLink key={key} title={elem.title} url={elem.url}
                                                           morelinks={elem.more}/>
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
        
    );
}

export default HeaderTemp;

