import React, { useLayoutEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ContactUs from "../../components/ContactUs";
import Config from "../../config";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
//import Content from "../content";
import MetaTags from 'react-meta-tags';

const Contact = () => {
  const [username, setUsername] = useState();
  const [address, setAddress] = useState();
  const [age, setAge] = useState();
  const [phone, setPhone] = useState();
  const [text, setText] = useState();
  const [sendData, setSendData] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);

  const submit = (e) => {
    e.preventDefault();

    if (!username || !address || !age || !phone) {
      setError("השדות המסומנים ב * הם שדות חובה");
      return false;
    }

    const formData = new FormData();

    formData.append('username', username);
    formData.append('address', address);
    formData.append('age', age);
    formData.append('phone', phone);
    formData.append('text', text);
    formData.append('job', 1);
    formData.append('file', selectedFile);

    // const data = {
    //   username: username,
    //   address: address,
    //   age: age,
    //   phone: phone,
    //   text: text,
    //   job: 1,
    //   file: selectedFile
    // };
    // file: fileCv
    fetch(Config.baseUrl + "contact-api.php?act=saveDrushimApi", {
      method: "post",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.act) {
          setSendData(true);
        }
      });
  };

  const usernameHandel = (e) => {
    setUsername(e.target.value);
  };
  const location = (e) => {
    setAddress(e.target.value);
  };
  const old = (e) => {
    setAge(e.target.value);
  };
  const phoneHandel = (e) => {
    setPhone(e.target.value);
  };
  const textarea = (e) => {
    setText(e.target.value);
  };
  const handleFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  useLayoutEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await fetch(
          Config.baseUrl + "contact-api.php?act=getContentJob"
        );
        response = await response.json();
        setData(response);
      } catch (e) {
        console.error(e);
      }
    }

    fetchMyAPI();
  }, []);

  return (
    <>
      <Col md={12}>
        <MetaTags>
          <title>{data && data.seo && data.seo.metaTitle ? 'הולמרקום - '+data.seo.metaTitle : ""}</title>
          <meta
            name="description"
            content={data && data.seo && data.seo.metaDescription ? data.seo.metaDescription : ""}
          />
        </MetaTags>
        {
          <Row>
            <Col>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  דף הבית
                </Link>
                <Typography color="textPrimary">{"דרושים דיילים"}</Typography>
              </Breadcrumbs>
            </Col>
          </Row>
        }
      </Col>
      <Col md={12}>
        {data ? (
          <ContactUs
            title={""}
            promo={data.text}
            titleForm={" מועמד יקר, נא מלא את פרטייך ונשוב אלייך בהקדם."}
            submit={submit}
            send={sendData}
            error={error}
            link101={data.link101}
            Linkemployee={data.Linkemployee}
            titleBanner={data.titleBanner}
            inputs={[
              {
                col: 6,
                name: "username",
                value: "",
                placeholder: "*שם מלא",
                require: true,
                onchange: usernameHandel,
              },
              {
                col: 6,
                name: "username",
                value: "",
                placeholder: "*איזור מגורים",
                require: true,
                onchange: location,
              },
              {
                col: 6,
                name: "username",
                value: "",
                placeholder: "*גיל",
                require: true,
                onchange: old,
              },
              {
                col: 6,
                name: "username",
                value: "",
                placeholder: "*מספר טלפון",
                require: true,
                onchange: phoneHandel,
              },
              {
                col: 12,
                textarea: true,
                name: "username",
                value: "",
                placeholder: "*מספר טלפון",
                require: true,
                onchange: textarea,
              },
            ]}
            filesupload={{
              col: 12,
              name: "files",
              onchange: handleFileUpload
            }}
          />
        ) : (
          ""
        )}
      </Col>
    </>
  );
};
export default Contact;
