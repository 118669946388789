import React from "react";

const InputTemp = (props) => {

    const inputsStyle = {
        padding: '15px',
        width: '100%',
    };

    return (
        <input onChange={props.onChange} style={inputsStyle} type={props.type} value={props.value} placeholder={props.placeholder} name={props.name} />
    )
};

export default InputTemp;
