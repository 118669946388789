import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Config from "../../config";
import ImageGallery from "react-image-gallery";
import Modal from "react-bootstrap/Modal";
import pixelShadow from "../../images/pixelShadow.png";
import {Breadcrumbs, rgbToHex, Typography} from "@material-ui/core";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

const Index = () => {
  const [gallerys, setGallerys] = useState();
  const [images, setIamges] = useState([]);

  useEffect(() => {
    async function getAPI() {
      try {
        // // todo: get one page content
        let response = await fetch(
          Config.baseUrl + "projects-api.php?act=getBoxs"
        );
        response = await response.json();
        setGallerys(response);
      } catch (e) {
        console.error(e);
      }
    }
    getAPI();
  }, []);

  const Boxs = (props) => {
    const boxStyle = {
      position: "absolute",
      bottom: 0,
      textAlign: "center",
      color: "white",
      width: "100%",
      right: 0,
    /*  backgroundImage: `url(${pixelShadow})`,
      backgroundClip: "content-box",
      backgroundSize: "contain",*/
      background:rgbToHex('#01010166'),
      backgroundClip: "content-box",
      zIndex:10
    };

    const titleBoxStyyle = {
      fontWeight: "900",
      fontSize: "18px",
    };
    const textBoxStyyle = {
      fontWeight: "700",
    };
    const locationBoxStyyle = {
      fontSize: "13px",
    };

    return (
      <Row className={'row-element'}>
        <Col
          md={12}
          variant="primary"
          onClick={handleShow}
          data-id={props.id}
          style={{ cursor: "pointer" }}
        >
          <img src={Config.imgUrl + props.img} className={"img-fluid"} alt="" />
          <Row>
            <Col style={boxStyle}>
              <Row>
                <Col md={12} style={titleBoxStyyle}>
                  <h2 style={{ fontSize: 22 }}> {props.company}</h2>
                </Col>
                <Col md={12} style={textBoxStyyle}>
                  {props.activity}
                </Col>
                <Col md={12} style={locationBoxStyyle}>
                  {props.lcoation}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = async (event) => {
    const idGallery = event.currentTarget.getAttribute("data-id");

    try {
      let response = await fetch(
        Config.baseUrl + "projects-api.php?act=getImages&id=" + idGallery
      );
      response = await response.json();
      if (response) {
        setIamges(response.images);
        setShow(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Col md={12}>
        <MetaTags>
          <title>הולמרקום - פרוייקטים</title>
          <meta name="description" content={'הולמרקום - חברת הדיילות הגדולה והמנוסה בישראל לאירועים גאה לחלק חלק בעשרות פרוייקטים שיווקיים,לצפייה בפרוייקטים ולפרטים נוספים היכנסו עכשיו לאתר'} />
        </MetaTags>
      <Row>
        <Col>
          <Modal centered={true} show={show} onHide={handleClose}>
            <Modal.Body>
              <ImageGallery items={images} />
            </Modal.Body>
          </Modal>

          <Row>
            <Col md={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  דף הבית
                </Link>
                <Typography color="textPrimary">פרוייקטים</Typography>
              </Breadcrumbs>
            </Col>
            <Col md={12}>
              <h1 style={{ fontSize: 28 }}>{"פרוייקטים"}</h1>
            </Col>
            {!gallerys ? (
              <Col md={12} className={"text-center loader"}>
                loading...
              </Col>
            ) : (

              gallerys["data"].map((value, key) => {


                return (
                  <Col xl={3} md={4} className={"mb-2  flex-height"} key={key}>
                    <Boxs
                      id={value.id}
                      img={value.img}
                      logo={value.logo}
                      company={value.company}
                      activity={value.activity}
                      lcoation={value.lcoation}
                    />
                  </Col>
                );
              })
            )}
          </Row>
        </Col>
      </Row>
    </Col>

  );

};
export default Index;
