import React, { useLayoutEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Config from "../../config";
import pixelShadow from "../../images/pixelShadow.png";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import Carousel from 'react-bootstrap/Carousel'


const imagesStyle = {
  width: "100%",
  height: "100%",
};

const ImageBox = (props) => {
  const titleBoxStyle = {
    position: "absolute",
    bottom: "8px",
    right: 0,
    marginRight: "10px",
    paddingRight: "5px",
    color: "white",
    textAlign: "right",
    lineHeight: "23px",
    borderRight: "5px solid " + props.borderColor,
  };
  const titleStyle = {
    fontSize: "25px ",
    fontWeight: 500,
    margin:0
  };

  const pixcelShadow = {
    backgroundImage: `url(${pixelShadow})`,
    backgroundClip: "content-box",
    backgroundSize: "contain",
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "66px",
  };

  const colorPoint = {
    width: "100px",
    height: "150px",
    background: props.borderColor,
    zIndex: "999",
    position: "absolute",
    top: "-4.5rem",
    left: "-4.5rem",
    transform: "rotate(45deg)",
  };
  if(Array.isArray(props.src)){
    return (
      <Row className={"h-100 m-0"}>
        <Col className={"p-0"} style={{ overflow: "hidden" }}>
        <span className={"color-point"} style={colorPoint} />
          <Carousel indicators={false} controls={false}>
          { 
          props.src.map((elem, key) => {
            return (
              <Carousel.Item key={key}>
                <img
                  key={key}
                  className="d-block w-100"
                  src={Config.imgUrl +elem}
                />
              </Carousel.Item>
            )
          }) 
          }
          </Carousel>
          <div style={titleBoxStyle}>
            <div><h2 style={titleStyle}>{props.title}</h2></div>
            <div>{props.subTitle}</div>
          </div>
        </Col>
      </Row>
    );
  }else{
    return (
      <Row className={"h-100 m-0"}>
        <Col className={"p-0"} style={{ overflow: "hidden" }}>
          <span className={"color-point"} style={colorPoint} />
          <img src={Config.imgUrl + props.src} style={imagesStyle} alt="" />
          <div style={pixcelShadow} />
          <div style={titleBoxStyle}>
            <div><h2 style={titleStyle}>{props.title}</h2></div>
            <div>{props.subTitle}</div>
          </div>
        </Col>
      </Row>
    );
  }
  
};

const Home = () => {
  const [data, setData] = useState(null);

  useLayoutEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await fetch(Config.baseUrl + "home-api.php?act=get");
        response = await response.json();
        setData(response);
      } catch (e) {
        console.error(e);
      }
    }

    fetchMyAPI();
  }, []);

  return (
    <Col className={"text-center"}>
      <MetaTags>
        <title>{(data && data.metaTitle ? data.metaTitle : '')}</title>
        <meta name="description" content={(data && data.metaDescription ? data.metaDescription : '')} />
      </MetaTags>
      <Row>
        {!data ? (
          <Col className={"loader"}>Loading...</Col>
        ) : (
          <Col>
            <Row>
              <Col md={6}>
                <Link to={data["rightImg"]["url"]}>
                  <ImageBox
                    src={data["rightImg"]["img"]}
                    title={data["rightImg"]["title"]}
                    subTitle={data["rightImg"]["text"]}
                    borderColor={"#dfa002"}
                  />
                </Link>
              </Col>
              <Col md={6}>
                <Row className={"h-100"}>
                  <Col md={12}>
                    <Row className={"h-100"}>
                      {data["fourBox"].map((elem, key) => {
                        return (
                          <Col
                            md={6}
                            key={key}
                            className={
                              (key > 1 ? "mt-md-2 " : "mt-md-0") +
                              (key % 2 === 0 ? " pl-md-1 " : " pr-md-1 ") +
                              " mt-2"
                            }
                          >
                            <Link to={elem.url}>
                              <ImageBox
                                key={key}
                                src={elem.img}
                                title={elem["title"]}
                                promo={elem.promo}
                                subTitle={elem["text"]}
                                borderColor={elem.color}
                              />
                            </Link>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default Home;
