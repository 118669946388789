import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import InputTemp from "./InputComponent";
import Config from "../config";
import { Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import SpleSlider from '../components/Carousel';
import FacebookIcon from "./images/Facebook.png";
import YouYube from "./images/YouYube.png";


function FooterTemp() {

    let location  = useLocation();

    const Icons = (props) => {
        return (
            <Col className={"col-auto"}>
                <img src={props.img} alt=""/>
            </Col>
        )
    };
    const LinkSocial = (prop) => {

        if(prop.link) {
            return (
                <a href={prop.link}   target={"_blank"} >
                    <Icons img={prop.icon}/>
                </a>
            )
        }else{
            return  "";
        }
    };
    const Box = (props) => {

        const style = {
            background: props.backgroundColor,
            padding: '40px',
            fontSize: '30px',
            fontWeight: 800,
            color: 'white',
            textAlign: 'center'
        };
        return (
            <Col md={3} className={'mt-md-0 mt-2'}>
                {
                    props.href ?
                        <a target={(props.target ? '_blank' : '')} href={props.href}>
                            <div style={style}>
                                {props.title}
                            </div>
                        </a>
                        :
                        <Link to={props.to}>
                            <div style={style}>
                                {props.title}
                            </div>
                        </Link>
                }

            </Col>
        )
    }

    const Newsletter = () => {

        const borderStyle = {
            borderTop: '4px dotted black',
            borderBottom: '4px dotted black',
            margin: 0,
            paddingTop: '25px',
            paddingBottom: '25px',
            alignItems: 'center'
        };
        const btnStyle = {
            padding: '15px',
            width: '100%',
            background: 'black',
            color: 'white',
            border: 0
        };

        const [username, setUsername] = useState();
        const [phone, setPhone] = useState();
        const [email, setEmail] = useState();
        const [error, setError] = useState('');
        const [send, setSend] = useState(false);

        const handleSubmit = (e) => {
            e.preventDefault();

            if (!username || !phone || !email) {
                setError('השדות המסומנים ב * הם שדות חובה');
                return false;
            }

            const data = {
                username: username,
                phone: phone,
                email: email
            };

            fetch(Config.baseUrl + 'contact-api.php?act=saveDrushimApiContactUs', {
                method: 'post',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.act) {
                        setSend(true);
                    }
                })
        }

        return (
            <div>
                <form className={'row'} style={borderStyle} onSubmit={handleSubmit}>
                    {
                        (send ?
                                <Col className={'text-center alert-success'}>הבקשה נשלחה בהצלחה</Col>
                                :
                                <Col md={12}>
                                    <Row>
                                        <Col className={'col-md-auto col-12 mt-md-0 mt-2 '} style={{fontSize: '30px', fontWeight: 800}}>
                                            רוצים לשמוע עוד...
                                        </Col>
                                        <Col className={'mt-md-0 mt-2'}>
                                            <InputTemp placeholder={'* שם מלא'} type={'text'} onChange={(e) => {
                                                setUsername(e.target.value)
                                            }}/>
                                        </Col>
                                        <Col className={'col-12 col-md mt-md-0 mt-2'}>
                                            <InputTemp placeholder={'* טלפון'} type={'text'} onChange={(e) => {
                                                setPhone(e.target.value)
                                            }}/>
                                        </Col>
                                        <Col className={'mt-md-0 mt-2'}>
                                            <InputTemp placeholder={'* דואר אלקטרוני'} type={'text'} onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}/>
                                        </Col>
                                        <Col md={1} className={"p-md-0 mt-md-0 mt-2"}>
                                            <input style={btnStyle} type={'submit'} value="שליחה"/>
                                        </Col>
                                        <Col md={12} className={"text-danger text-center mt-2"}>
                                            {error}
                                        </Col>
                                    </Row>
                                </Col>
                        )
                    }
                </form>
            </div>
        );
    };

    const [textFooter, setTextFooter] = useState();
    const [logosSlider, setLogosSlider] = useState();
    const [footerLinks, setFooterLinks] = useState();
    const [linkSocial, seLinkSocial] = useState();
    useEffect(() => {
        try {
            async function fetchAPI() {
                try {
                    let response = await fetch(Config.baseUrl + 'footer-api.php?act=getText');
                    response = await response.json();
                    setTextFooter(response.text);

                    let responseLogos = await fetch(Config.baseUrl + 'home-api.php?act=bannersClinets');
                    response = await responseLogos.json();
                    setLogosSlider(response);

                    let footerLinks = await fetch(Config.baseUrl + 'header-api.php?act=getFooter');
                    response = await footerLinks.json();
                    setFooterLinks( response);
                    console.log(Config.baseUrl + 'header-api.php?act=get');
                   let objinkSocial = await fetch(Config.baseUrl + 'header-api.php?act=get');
                   response = await objinkSocial.json();
                    console.log("response",response);
                    seLinkSocial( response);
                 
                } catch (e) {
                    console.error(e);
                }

            }

            fetchAPI();
        } catch (e) {
            console.error(e);
        }

    }, []);

    return (
        <Row className={'m-0'}>
            <Col md={12} className={"mt-3 mb-3"}>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Box title={'המגזין שלנו'} backgroundColor={'#dfa002'} target={true}
                                 href={'https://blog.hallmarcom.co.il/'}/>
                            <Box title={'צרו קשר'} backgroundColor={'#00898a'} to={'/contactUs'}/>
                            <Box title={'דרושים'} backgroundColor={'#8ac83e'} to={'/contactUs/job'}/>
                            <Box title={'פרוייקטים'} backgroundColor={'#d0131b'}  to={'/projects'} />
                        </Row>
                        
                    </Col>
                </Row>
            </Col>
            {
                (location.pathname === '/' || location.pathname === '/home' ? 
                <Col md={12}>
                    <Row className={'justify-content-center mt-5'}>
                        <Col md={12}>
                            <p dangerouslySetInnerHTML={{__html:textFooter}} />
                        </Col>
                    </Row>
                </Col>
             : '')
            }
            
            <Col md={12} className={'mt-5 mb-5'}>
                <Newsletter/>
            </Col>
            <Col md={12} className={'mt-5 mb-5'}>
                {logosSlider? <SpleSlider slidesToShow={6} slidesToScroll={2} array={logosSlider} /> : ''}
            </Col>
            <Col md={12} className={'mt-4 mb-4'}>
                <Row>
                  {
                      (
                        footerLinks ? 
                        Object.keys(footerLinks).map(function(key) {
                            return (
                                <Col key={key}>
                                <ul style={{listStyleType: 'none'}}>
                                        <li><b>{key}</b></li>
                                        {
                                            footerLinks[key].map(function(val, key){
                                                return(
                                                <li key={key}>
                                                    <Link to={val.link}>
                                                        {val.title}
                                                    </Link>
                                                </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </Col>
                            )
                        })
                    :
                    ''
                    )
                  }
                  <Col>
                    <a target="_blank" href={'https://blog.hallmarcom.co.il/'} style={{background: '#dfa002' , padding: '8px 17px 8px 17px', color: 'white'}}>
                            {'למגזין שלנו'}
                        </a>
                  </Col>
                  <Col>
                    <Row>
                        <Col md={12}>

                        <img src='/static/media/Logo.9014b73a.png' className={'img-fluid'} alt=""/>
                        </Col>
                         <Col md={12} className={'mt-2'}>
                            <Row className={'justify-content-center'}>
                                <LinkSocial link={linkSocial?.facebook} icon={FacebookIcon}/>
                                <LinkSocial link={linkSocial?.youtube} icon={YouYube}/>
                            </Row>
                        </Col>
                    </Row>
                  </Col>
                </Row>
            </Col>
        </Row>
    )

}

export default FooterTemp;
