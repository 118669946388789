import React , {Component} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Col} from "react-bootstrap";
import Config from "../config";
 
class SimpleSlider extends Component {


  constructor(props){
    super(props);
    this.state = {
      images: props.array,
      settings: {
        dots: (props.dots ? props.dots : false ),
        infinite: true,
        autoplay: true,
        speed: (props.speed ? props.speed : 600 ),
        slidesToShow: (props.slidesToShow ? (window.innerWidth < 520 ? props.slidesToShow / 2 : props.slidesToShow ) : 6 ),
        slidesToScroll: (props.slidesToScroll ? props.slidesToScroll : 1 )
      },
    };
  };


  render(props) {
    return (
      (this.state.images ? 
          <div>
            <Col className={'text-center'} style={{background:'white'}}>
              <div className={'m-0 pt-4'} style={{fontSize:25}} ><b>בין לקוחותינו</b></div>
            </Col>
            <Slider {...this.state.settings}>
            { this.state.images.map(function( val, key) {
              return (
                <div className="text-center p-2" key={key}>
                  <img style={{width: '160px' , margin: '0 auto'}} className="img-fluid" src={Config.baseUrl + val.logo} alt={val.title} />
                </div>
              )
            }) }
          </Slider>
          </div>
        : '')
    );
  }
}

export default SimpleSlider