import React, { useEffect, useState } from "react";
import Content from "../content";
import Config from "../../config";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

const Index = () => {
  const [data, setData] = useState();
  let { id } = useParams();

  useEffect(() => {
    async function getAPI() {
      try {
        // // todo: get one page content
        let response = await fetch(
          Config.baseUrl + "blog-api.php?act=getArticle&id=" + id
        );
        response = await response.json();
        console.log(response)
        setData(response);
      } catch (e) {
        console.error(e);
      }
    }
    getAPI();
  }, [id]);

  return (
    <Col md={12}>
      <MetaTags>
        <title>{data && data.titleTag ? 'הולמרקום - '+data.titleTag : ""}</title>
        <meta name="description" content={data && data.descrTag ? data.descrTag : ""} />
        <meta name="keywords" content={data && data.keyTag ? data.keyTag : ""} />
      </MetaTags>
      {data ? (
        <Row className="contentFixMarginCrm">
          <Col>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" to="/">
                דף הבית
              </Link>
              {
                data && data.category && data.category.title ?
                  <Link color="inherit" to={data.category.url}>
                    {data.category.title}
                  </Link>
                  :
                  ''
              }
              
              <Typography color="textPrimary">{data.title}</Typography>
            </Breadcrumbs>
          </Col>
          <Content
            img={data.img}
            twoImg={data.imgTwo}
            title={data.title}
            text={data.text}
            promo={data.promo}
          />
        </Row>
      ) : (
        ""
      )}
    </Col>
  );
};

export default Index;
